<!--设置管理--客户中心-->
<template>
  <div
    id="customercenter"
    ref="customercenter"
    v-loading="loading"
    :element-loading-text="$t('customerCenter.a1')"
  >
    <div class="interface">
      <div class="pageTitle"> 
        <div class="pageTitle-left">
          <img src="../../../public/images/set/setindex01.png" />
          <span>{{ $t("customerCenter.a2") }}</span>
        </div>
        <div>
          <a-icon type="close" :style="{ color: 'rgba(12, 12, 12, 0.65)' }" @click="Fallback"/>
        </div>
      </div>
      <div class="header">
        <a-input-search
          v-model="search"
          :placeholder="$t('customerCenter.a135')"
          style="width: 250px"
          allow-clear
        />
      </div>
      <div class="main">
        <div class="left">
          <a-menu
            v-model="current"
            v-if="searchList.length !== 0"
            mode="inline"
          >
            <a-menu-item
              v-for="itme in searchList"
              @click="detailsInfo"
              :key="itme.id"
            >
              {{ itme.clientName }}
            </a-menu-item>
          </a-menu>
          <a-empty v-else />
        </div>
        <div class="right">
          <div class="right_main">
            <div class="right_main_left">
              <div class="card">
                <img src="../../../public/images/set/usericon.png" />
                <br />
                <p class="card_title">{{ detailedInfo.clientName | none }}</p>
                <span class="card_title">
                  {{ detailedInfo.clientNameKana | none }}
                </span>
              </div>
              <div>
                <div class="info_site">
                  <img
                    class="imgs"
                    src="../../../public/images/set/location.png"
                  />
                  <span>{{ detailedInfo.address | none }}</span>
                </div>
                <div class="info_site">
                  <img
                    class="imgs"
                    src="../../../public/images/set/phone.png"
                  />
                  <span>{{ relation }}</span>
                </div>
                <div class="info_site">
                  <img
                    class="imgs"
                    src="../../../public/images/set/mailbox.png"
                  />
                  <span>{{ detailedInfo.clientWindow | none }}</span>
                </div>
              </div>
            </div>
            <div>
              <a-button type="primary" ghost @click="compile">
                <a-icon type="form" />{{ $t("customerCenter.a3") }}
              </a-button>
            </div>
          </div>
          <div class="card_main">
            <my-headertitle>{{ $t("customerCenter.a4") }}</my-headertitle>
            <div class="card_content">
              <div class="container">
                <div
                  class="chunk"
                  v-for="(itme, index) in areaList"
                  :key="index"
                  @click="goDetails(itme)"
                >
                  <img src="../../../public/images/set/home_normal.png" />
                  <div class="aaa">
                    <span class="chunk_title" :title="itme.name">
                      {{ itme.name }}
                    </span>
                  </div>
                </div>
                <div class="emptys" v-for="itme in 8" :key="itme + 'n'"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      :title="$t('customerCenter.a5')"
      centered
      :visible="visible"
      :width="700"
      :destroyOnClose="true"
      :getContainer="() => this.$refs.customercenter"
      @cancel="Cancel"
    >
      <template slot="footer">
        <a-button key="back" @click="Cancel">
          {{ $t("customerCenter.a6") }}
        </a-button>
        <a-button key="submit" type="primary" @click="compileOK">
          {{ $t("customerCenter.a7") }}
        </a-button>
      </template>
      <div
        v-loading="infoloading"
        :element-loading-text="$t('customerCenter.a1')"
      >
        <a-form-model
          ref="ruleForm"
          :model="formInfo"
          layout="vertical"
          :rules="rules"
        >
          <a-row :gutter="64">
            <a-col :span="10">
              <a-form-model-item
                :label="$t('customerCenter.a8')"
                prop="clientName"
              >
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a9')"
                  v-model="formInfo.clientName"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item :label="$t('customerCenter.a10')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a11')"
                  v-model="formInfo.clientNameKana"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="64">
            <a-col :span="10">
              <a-form-model-item :label="$t('customerCenter.a12')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a13')"
                  v-model="formInfo.daytimeContact"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item :label="$t('customerCenter.a14')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a13')"
                  v-model="formInfo.nighttimeContact"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="64">
            <a-col :span="10">
              <a-form-model-item :label="$t('customerCenter.a15')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a16')"
                  v-model="formInfo.clientWindow"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item :label="$t('customerCenter.a17')">
                <a-input
                  allow-clear
                  :placeholder="$t('customerCenter.a18')"
                  v-model="formInfo.address"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import headerTitle from "../../components/Title/headerTitle";
import {
  getcustlist,
  getcustinfo,
  getbuildinglist,
  custupdate,
} from "../../api/setManagement";

export default {
  name: "customercenter",
  data() {
    return {
      visible: false,
      loading: false,
      infoloading: false,
      clientList: [],
      searchList: [],
      current: [],
      detailedInfo: {},
      formInfo: {},
      search: "",
      areaList: [],
      images: require("../../../public/images/set/setindex01.png"),
      rules: {
        clientName: [
          {
            required: true,
            message: this.$t("customerCenter.a9"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  filters: {
    none(msg) {
      if (msg == null) {
        return "----";
      } else {
        return msg;
      }
    },
  },
  watch: {
    search: {
      handler(newValue) {
        let list = this.clientList;
        let searchList = list.filter(
          (row) => row.clientName.indexOf(newValue) !== -1
        );
        this.searchList = searchList;
      },
      immediate: true,
    },
  },
  computed: {
    relation() {
      let value1 = this.detailedInfo.daytimeContact;
      let value2 = this.detailedInfo.nighttimeContact;
      if (value1 == null && value2 == null) {
        return "----";
      } else if (value1 == null) {
        return value2;
      } else if (value2 == null) {
        return value1;
      } else {
        return `${value1} / ${value2}`;
      }
    },
  },
  mounted() {
    // 页面加载数据初始化
    this.loading = true;
    getcustlist()
      .then((res) => {
        // console.log(res);
        this.loading = false;
        const { data } = res;
        const key = data[0].id;
        this.clientList = data;
        this.searchList = data;
        this.current.push(key);
        this.detailsInfo({ key });
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  methods: {
    Fallback(){
      this.$router.go(-1)
    },
    // 客户信息设置取消按钮
    Cancel() {
      this.visible = false;
    },
    // 页面编辑按钮
    compile() {
      this.visible = true;
      let _val = JSON.stringify(this.detailedInfo);
      let val = JSON.parse(_val);
      this.formInfo = val;
    },
    // 客户信息设置窗口更新确认按钮
    compileOK() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.infoloading = true;
          const formData = new FormData();
          formData.append("id", this.current[0]);
          formData.append("name", this.formInfo.clientName);
          formData.append("otherName", this.formInfo.clientNameKana);
          formData.append("cantact", this.formInfo.clientWindow);
          formData.append("tel1", this.formInfo.daytimeContact);
          formData.append("tel2", this.formInfo.nighttimeContact);
          formData.append("addr", this.formInfo.address);
          // console.log(formData);
          custupdate(formData)
            .then((res) => {
              // console.log(res);
              this.infoloading = false;
              if (res.errorCode == "00") {
                const key = this.current[0];
                this.$message.success(res.msg);
                this.visible = false;
                this.detailsInfo({ key });
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 获取页面详情信息
    detailsInfo({ item, key }) {
      this.loading = true;
      let value = key;
      getcustinfo(value)
        .then((res) => {
          // console.log(res);
          const { data: dataInfo } = res;
          this.detailedInfo = dataInfo;
          getbuildinglist(key)
            .then((res) => {
              console.log(res);
              this.loading = false;
              const { data: areaList } = res;
              this.areaList = areaList;
            })
            .catch((err) => {
              console.log(err);
              this.loading = false;
            });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    // 点击建筑物卡片跳转至建筑详情页
    goDetails(value) {
      this.$store.commit("areaDetails/areaDetails", value);
      this.$router.push({
        path: "/set/areaDetails",
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    handleProvinceChange(value) {
      this.cities = cityData[value];
      this.secondCity = cityData[value][0];
    },
  },
  components: {
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#customercenter {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px #bdbcbc;
  position: relative;
}
.interface {
  width: 100%;
  height: 100%;
  padding: 10px 20px 20px 20px;
  background-color: #ffffff;
  overflow: hidden;
}
.pageTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  border-bottom: 1px solid rgba(12, 12, 12, 0.2);
  padding-bottom: 10px;
}
.pageTitle-left{
  display: flex;
  align-items: center;
}
.pageTitle img{
  width: 45px;
  margin-right: 10px;
}
.header {
  padding: 15px 0px;
  overflow: hidden;
}
.left {
  width: 250px;
  height: 100%;
  border-right: 1px solid rgba(12, 12, 12, 0.2);
  overflow: hidden;
  transition: 200ms;
}
.left:hover {
  overflow: auto;
}
.right {
  width: calc(100% - 250px);
  padding-left: 20px;
}
.main {
  display: flex;
  height: calc(100% - 114.25px);
}
.right_main {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.card {
  width: 250px;
  height: 130px;
  padding: 10px 20px;
  margin-right: 30px;
  background-color: #7682ce;
  border-radius: 3px;
}
.card_title {
  color: #ffffff;
  font-size: 14px;
}
.right_main_left {
  display: flex;
}
.imgs {
  width: 20px;
  margin-right: 10px;
}
.info_site {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: rgba(12, 12, 12, 0.65);
  font-size: 13px;
}
.card_main {
  height: calc(100% - 130px);
  overflow: hidden;
}
.card_content {
  height: calc(100% - 51px);
  padding-top: 10px;
  overflow: auto;
}
.container {
  max-height: 500px;
  /* padding: 10px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.chunk {
  display: flex;
  align-items: center;
  width: 230px;
  height: 70px;
  padding-left: 5px;
  background: #f2f4f7;
  margin-bottom: 15px;
  border: 1px solid #eff8fb;
  border-radius: 3px;
  cursor: pointer;
  transition: 200ms;
}
.chunk div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chunk img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.chunk_title {
  font-size: 15px;
  color: #7682ce;
}
.chunk:hover {
  border: 1px solid #7682ce;
}
.title {
  width: 100px;
  padding: 0px 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.emptys {
  width: 230px;
  height: 1px;
}
.empty {
  width: calc(100% - 250px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>